import palette from "./palette"

export default {
  fontFamily: [
    "Geogrotesque",
    "Verdana",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "46px",
    lineHeight: "1.4",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: palette.white,
  },
  h2: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "34px",
    lineHeight: "1.2",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: palette.white,
  },
  h3: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "1.3",
    letterSpacing: "0.02em",
    color: palette.white,
  },
  h4: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "1.4",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: palette.white,
  },
  h5: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.4",
    textTransform: "uppercase",
    color: palette.grey[40],
  },
  body1: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "17px",
    lineHeight: "1.6",
    color: palette.grey[20],
    whiteSpace: "pre-line",
  },
  body2: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "15px",
    lineHeight: "1.5",
    color: palette.grey[20],
    whiteSpace: "pre-line",
  },
  button: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "1.6",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: palette.grey[100],
  },
  caption: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "1.2",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    color: palette.primary.main,
  },
  overline: {
    fontFamily: "Geogrotesque",
    fontStyle: "bold",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "1.4",
    color: palette.primary.main,
  },
}
