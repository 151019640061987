export default {
  primary: {
    main: "#F26522",
    light: "#FF6a24",
  },
  secondary: {
    main: "#010000",
  },
  white: "#FFFFFF",
  grey: {
    10: "#E6E6E6",
    20: "#CCCCCC",
    40: "#D4D7D9",
    70: "#DADADA",
    80: "#999",
    90: "#1E2021",
    100: "#010000",
  },
  background: {
    default: "#010000",
  },
}
