import palette from "../palette"

export default {
  root: {
    boxShadow: "none",
    borderRadius: 0,
    border: 0,
    height: "50px",
  },
  contained: {
    boxShadow: "none",
    padding: "28px",
    backgroundColor: "#F26522ee",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: palette.primary.light,
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  outlined: {
    boxShadow: "none",
    border: "2px solid",
    padding: "26px",
    borderColor: palette.primary.main,
    color: palette.primary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: palette.primary.main,
      color: "#000",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}
