import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import overrides from "./overrides"
import palette from "./palette"
import typography from "./typography"

const themeName = "Digic"

const themeData = {
  palette,
  typography,
  overrides,
}

export default responsiveFontSizes(createMuiTheme({ ...themeData, themeName }))
