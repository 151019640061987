import React from "react"
import { HrDialogProvider } from "./src/components/utils/hr-dialog"

export const wrapPageElement = ({ element }) => {
  return (
    <div id="app-wrapper">
      <HrDialogProvider>{element}</HrDialogProvider>
    </div>
  )
}
