import React, { useContext } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import { Typography, makeStyles, Box } from "@material-ui/core"
import { useState } from "react"

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: "#000",
    border: "2px solid #333",
    padding: "48px 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px",
    },
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  button: {
    marginRight: "16px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0",
    },
  },
}))

function HrDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={open}
        id="customized-dialog-title"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.wrapper}>
          <Box style={{ margin: "8px" }}>
            <Typography variant="h2" style={{ marginBottom: 8 }}>
              {"YOU ARE NOW DIRECTED TO OUR CAREER PORTAL"}
            </Typography>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body1" style={{ marginBottom: 8 }}>
                Please note that this portal is operated by{" "}
                <a
                  href="https://hireify.hu"
                  style={{ color: "#F26522" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  hireify.hu Ltd.
                </a>{" "}
                Ltd. By opening the site, you agree to the{" "}
                <a
                  href="https://hireify.hu/assets/HRSzoftver_Data_Policy.pdf"
                  style={{ color: "#F26522" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  General Data Protection Statement and Privacy Policy
                </a>{" "}
                of{" "}
                <a
                  href="https://hireify.hu"
                  style={{ color: "#F26522" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  hireify.hu Ltd.{" "}
                </a>{" "}
                Ltd.
              </Typography>
            </DialogContentText>
          </Box>
          <DialogActions>
            <Box className={classes.buttonWrapper}>
              <Button
                variant="outlined"
                onClick={handleClose}
                className={classes.button}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                target="_blank"
                rel="noopener noreferrer"
                href="https://career.digicpictures.com/jobs?"
                className={classes.button}
              >
                Open hr website
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  )
}

const HrDialogContext = React.createContext()

export const HrDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <HrDialogContext.Provider value={() => setOpen(true)}>
        {children}
      </HrDialogContext.Provider>
      <HrDialog open={open} setOpen={setOpen}></HrDialog>
    </>
  )
}

export const useOpenHrDialog = () => {
  const openHrDialog = useContext(HrDialogContext)
  return openHrDialog
}
